import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "SuccessMessage"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")
  const _component_InputField = _resolveComponent("InputField")
  const _component_Select = _resolveComponent("Select")
  const _component_Toggle = _resolveComponent("Toggle")
  const _component_ImageUpload = _resolveComponent("ImageUpload")
  const _component_Button = _resolveComponent("Button")

  return (_ctx.data)
    ? (_openBlock(), _createBlock("form", {
        key: 0,
        onSubmit: _cache[22] || (_cache[22] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"])),
        ref: "form"
      }, [
        _createVNode(_component_Divider, null, {
          default: _withCtx(() => [
            _createVNode("span", null, _toDisplayString(_ctx.mixWB('W: Om virksomheden')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_InputField, {
          name: "name",
          label: "Navn",
          data: _ctx.data.name,
          "onUpdate:data": _cache[1] || (_cache[1] = ($event: any) => (_ctx.data.name = $event)),
          required: ""
        }, null, 8, ["data"]),
        _createVNode(_component_InputField, {
          name: "companyInitials",
          label: "Virksomhedens initialer (brugt til sagsnummer)",
          data: _ctx.data.companyInitials,
          "onUpdate:data": _cache[2] || (_cache[2] = ($event: any) => (_ctx.data.companyInitials = $event)),
          required: ""
        }, null, 8, ["data"]),
        _createVNode(_component_InputField, {
          name: "address",
          label: "Adresse",
          data: _ctx.data.address,
          "onUpdate:data": _cache[3] || (_cache[3] = ($event: any) => (_ctx.data.address = $event)),
          required: ""
        }, null, 8, ["data"]),
        _createVNode(_component_InputField, {
          name: "postalCode",
          label: "Postnummer",
          data: _ctx.data.postalCode,
          "onUpdate:data": _cache[4] || (_cache[4] = ($event: any) => (_ctx.data.postalCode = $event)),
          required: ""
        }, null, 8, ["data"]),
        _createVNode(_component_InputField, {
          name: "city",
          label: "By",
          data: _ctx.data.city,
          "onUpdate:data": _cache[5] || (_cache[5] = ($event: any) => (_ctx.data.city = $event)),
          required: ""
        }, null, 8, ["data"]),
        _createVNode(_component_Select, {
          name: "baseCountry",
          label: "Land",
          options: _ctx.countryOptions,
          data: _ctx.data.baseCountry,
          "onUpdate:data": _cache[6] || (_cache[6] = ($event: any) => (_ctx.data.baseCountry = $event))
        }, null, 8, ["options", "data"]),
        _createVNode(_component_InputField, {
          name: "contactPerson.name",
          label: "Kontaktperson - Navn",
          data: _ctx.data.contactPerson.name,
          "onUpdate:data": _cache[7] || (_cache[7] = ($event: any) => (_ctx.data.contactPerson.name = $event)),
          required: ""
        }, null, 8, ["data"]),
        _createVNode(_component_InputField, {
          name: "contactPerson.phoneNumber",
          label: "Kontaktperson - Telefon",
          data: _ctx.data.contactPerson.phoneNumber,
          "onUpdate:data": _cache[8] || (_cache[8] = ($event: any) => (_ctx.data.contactPerson.phoneNumber = $event)),
          required: ""
        }, null, 8, ["data"]),
        _createVNode(_component_InputField, {
          name: "contactPerson.email",
          label: "Kontaktperson - Email",
          data: _ctx.data.contactPerson.email,
          "onUpdate:data": _cache[9] || (_cache[9] = ($event: any) => (_ctx.data.contactPerson.email = $event)),
          required: ""
        }, null, 8, ["data"]),
        _createVNode(_component_Divider, null, {
          default: _withCtx(() => [
            _createVNode("span", null, _toDisplayString(_ctx.mixWB('W: Laboratorier')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Toggle, {
          name: "allowMilvaEuroFins",
          label: "Kan bruge EuroFins gennem Milva",
          data: _ctx.data.allowMilvaEuroFins,
          "onUpdate:data": [
            _cache[10] || (_cache[10] = ($event: any) => (_ctx.data.allowMilvaEuroFins = $event)),
            _ctx.onAllowMilvaEuroFinsUpdate
          ]
        }, null, 8, ["data", "onUpdate:data"]),
        (_ctx.data.allowMilvaEuroFins)
          ? (_openBlock(), _createBlock(_component_Toggle, {
              key: 0,
              name: "billPerRequisition",
              label: "Fakturer pr rekvisition",
              data: _ctx.data.billPerRequisition,
              "onUpdate:data": _cache[11] || (_cache[11] = ($event: any) => (_ctx.data.billPerRequisition = $event))
            }, null, 8, ["data"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Select, {
          name: "primaryLabID",
          label: "Primary laboratory",
          required: "",
          options: _ctx.labOptions,
          data: _ctx.data.primaryLabID,
          "onUpdate:data": _cache[12] || (_cache[12] = ($event: any) => (_ctx.data.primaryLabID = $event))
        }, null, 8, ["options", "data"]),
        _createVNode(_component_Select, {
          name: "analysisDeliveryTimeOptionID",
          label: "Analysis delivery time",
          required: "",
          options: _ctx.analysisDeliveryTimeOptions,
          data: _ctx.data.analysisDeliveryTimeOptionID,
          "onUpdate:data": _cache[13] || (_cache[13] = ($event: any) => (_ctx.data.analysisDeliveryTimeOptionID = $event))
        }, null, 8, ["options", "data"]),
        _createVNode(_component_InputField, {
          name: "euroFinsNumber",
          label: "EuroFins Number",
          data: _ctx.data.euroFinsNumber,
          "onUpdate:data": _cache[14] || (_cache[14] = ($event: any) => (_ctx.data.euroFinsNumber = $event)),
          required: _ctx.data.primaryLabID === 'euroFins'
        }, null, 8, ["data", "required"]),
        _createVNode(_component_InputField, {
          name: "hojvangNumber",
          label: "Højvang Number",
          data: _ctx.data.hojvangNumber,
          "onUpdate:data": _cache[15] || (_cache[15] = ($event: any) => (_ctx.data.hojvangNumber = $event)),
          required: _ctx.data.primaryLabID === 'hojvang'
        }, null, 8, ["data", "required"]),
        _createVNode(_component_InputField, {
          name: "analysisReportEmail",
          label: "Email til modtagelse af analyserapporter",
          data: _ctx.data.analysisReportEmail,
          "onUpdate:data": _cache[16] || (_cache[16] = ($event: any) => (_ctx.data.analysisReportEmail = $event))
        }, null, 8, ["data"]),
        _createVNode(_component_Divider, null, {
          default: _withCtx(() => [
            _createVNode("span", null, _toDisplayString(_ctx.mixWB('W: Tilladte projekttyper')), 1)
          ]),
          _: 1
        }),
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.projectTypes, (option) => {
          return _withDirectives((_openBlock(), _createBlock(_component_Toggle, {
            key: option.id,
            name: option.title,
            label: option.title,
            data: option.isSelected,
            "onUpdate:data": ($event: any) => (_ctx.onProjectTypeUpdate(option.id))
          }, null, 8, ["name", "label", "data", "onUpdate:data"])), [
            [_vShow, option.isActive]
          ])
        }), 128)),
        _createVNode(_component_Divider, null, {
          default: _withCtx(() => [
            _createVNode("span", null, _toDisplayString(_ctx.mixWB('W: Automatiske tilvalg ved projektoprettelse')), 1)
          ]),
          _: 1
        }),
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.projectOptions, (option) => {
          return _withDirectives((_openBlock(), _createBlock(_component_Toggle, {
            key: option.id,
            name: option.title,
            label: option.title,
            data: option.isSelected,
            "onUpdate:data": ($event: any) => (_ctx.onProjectOptionUpdate(option.id))
          }, null, 8, ["name", "label", "data", "onUpdate:data"])), [
            [_vShow, option.isActive]
          ])
        }), 128)),
        _createVNode(_component_Divider, null, {
          default: _withCtx(() => [
            _createVNode("span", null, _toDisplayString(_ctx.mixWB('W: Andet')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Toggle, {
          name: "useMandatoryManualCaseNumbers",
          label: "Manuelle sagsnumre er obligatoriske",
          data: _ctx.data.useMandatoryManualCaseNumbers ,
          "onUpdate:data": _cache[17] || (_cache[17] = ($event: any) => (_ctx.data.useMandatoryManualCaseNumbers  = $event))
        }, null, 8, ["data"]),
        _createVNode(_component_Toggle, {
          name: "useSamplingID",
          label: "Brug prøvetagnings ID",
          data: _ctx.data.useSamplingID,
          "onUpdate:data": _cache[18] || (_cache[18] = ($event: any) => (_ctx.data.useSamplingID = $event))
        }, null, 8, ["data"]),
        _createVNode(_component_Toggle, {
          name: "isActive",
          label: "Aktiv",
          data: _ctx.data.isActive,
          "onUpdate:data": _cache[19] || (_cache[19] = ($event: any) => (_ctx.data.isActive = $event))
        }, null, 8, ["data"]),
        _createVNode(_component_ImageUpload, {
          disabled: !_ctx.data.id,
          label: "Main logo",
          storagePath: `Logos/${ _ctx.data.id }`,
          mimeType: "image/png",
          data: _ctx.data.images.mainLogo,
          "onUpdate:data": _cache[20] || (_cache[20] = ($event: any) => (_ctx.data.images.mainLogo = $event)),
          name: "mainLogo"
        }, null, 8, ["disabled", "storagePath", "data"]),
        _createVNode(_component_ImageUpload, {
          disabled: !_ctx.data.id,
          label: "Email logo",
          storagePath: `Logos/${ _ctx.data.id }`,
          mimeType: "image/png",
          data: _ctx.data.images.emailLogo,
          "onUpdate:data": _cache[21] || (_cache[21] = ($event: any) => (_ctx.data.images.emailLogo = $event)),
          name: "emailLogo"
        }, null, 8, ["disabled", "storagePath", "data"]),
        _createVNode(_component_Button, {
          disabled: _ctx.isLoading || !_ctx.isAdmin,
          type: "submit",
          label: "Submit"
        }, null, 8, ["disabled"]),
        (_ctx.successMessage)
          ? (_openBlock(), _createBlock("div", _hoisted_1, "Account saved"))
          : _createCommentVNode("", true)
      ], 544))
    : _createCommentVNode("", true)
}