
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import {
  computed, onMounted, ref, defineComponent,
} from 'vue'
import InputField from '@/components/Form/InputField.vue'
import Button from '@/components/Form/Button.vue'
import Toggle from '@/components/Form/Toggle.vue'
import ImageUpload from '@/components/Form/ImageUpload.vue'
import { IAccount, TUser } from '@/types'
import Select from '@/components/Form/Select.vue'
import { ICountry } from '@/types/countries'
import { mixWB } from '@/globals/javascript/utils/mixins'
import Divider from '@/components/Form/Divider.vue'
import {
  IAnalysisDeliveryTimeOptionV3, ILaboratoryV3, IProjectOptionV3, IProjectTypeV3,
} from '@/types/resources-version-3'

type PartialAccount = Omit<IAccount, 'createdDate' | 'nextScreeningNumber'>

export default defineComponent({
  components: {
    InputField,
    Button,
    Toggle,
    ImageUpload,
    Select,
    Divider,
  },
  setup() {
    const store = useStore()
    const { isAdmin } = store.getters
    const route = useRoute()
    const router = useRouter()
    const isNew = computed(() => route.name === 'ProAccountNew')
    const data = ref<PartialAccount | undefined>()
    const isLoading = ref(true)
    const successMessage = ref(false)
    onMounted(() => {
      data.value = {
        id: '',
        name: '',
        baseCountry: 'DK',
        address: '',
        postalCode: '',
        city: '',
        contactPerson: {
          name: '',
          phoneNumber: '',
          email: '',
        },
        primaryLabID: 'euroFins',
        analysisDeliveryTimeOptionID: 'ADO-6',
        euroFinsNumber: '',
        hojvangNumber: '',
        analysisReportEmail: '',
        companyInitials: '',
        isActive: true,
        useMandatoryManualCaseNumbers: false,
        useSamplingID: false,
        allowMilvaEuroFins: false,
        billPerRequisition: false,
        images: {
          mainLogo: '',
          emailLogo: '',
        },
        allowedProjectTypeIDs: [],
        autoSelectedProjectOptionIDs: [],
      }
      if (!isNew.value) {
        const existingAccount = store.getters.accounts.find(
          (account: IAccount) => account.id === route.params.accountID,
        )
        data.value = {
          ...data.value,
          ...existingAccount,
        }
      }
      isLoading.value = false
    })

    const onAllowMilvaEuroFinsUpdate = (value: boolean) => {
      // Check to reset primaryLabID
      if (!value && data?.value?.primaryLabID === 'milvaEuroFins') {
        data.value.primaryLabID = 'euroFins'
      }

      // Check to reset billPerRequisition
      if (!value && data?.value?.billPerRequisition) {
        data.value.billPerRequisition = false
      }
    }

    const onProjectTypeUpdate = (projectTypeID: string) => {
      if (!data?.value?.allowedProjectTypeIDs) {
        return
      }

      const index = data.value.allowedProjectTypeIDs.findIndex((x) => x === projectTypeID)

      if (index > -1) {
        data.value.allowedProjectTypeIDs.splice(index, 1)
      }
      else {
        data.value.allowedProjectTypeIDs.push(projectTypeID)
      }
    }

    const onProjectOptionUpdate = (projectOptionID: string) => {
      if (!data?.value?.autoSelectedProjectOptionIDs) {
        return
      }

      const index = data.value.autoSelectedProjectOptionIDs.findIndex((x) => x === projectOptionID)

      if (index > -1) {
        data.value.autoSelectedProjectOptionIDs.splice(index, 1)
      }
      else {
        data.value.autoSelectedProjectOptionIDs.push(projectOptionID)
      }
    }

    const onSubmit = () => {
      if (isLoading.value) {
        return
      }

      isLoading.value = true
      if (isNew.value) {
        store.dispatch('createAccount', { data: data.value })
          .then((accountID: string) => {
            router.push({ name: 'ProAccount', params: { accountID } })
            isLoading.value = false
          })
      }
      else {
        store.dispatch('updateAccount', { data: data.value, id: route.params.accountID })
          .then(() => {
            isLoading.value = false
            successMessage.value = true
            setTimeout(() => {
              successMessage.value = false
            }, 1000)
          })
      }
    }

    return {
      isAdmin,
      users: computed<TUser>(() => store.getters.usersByAccount(route.params.accountID)),
      countryOptions: store.getters.allActiveCountries.map((x: ICountry) => ({
        key: x.countryCode,
        value: mixWB(x.translation),
      })),
      labOptions: computed(() => {
        const options: { key: string, value: string }[] = []

        // Add lab options from DB
        store.getters.laboratoriesAsArrayV3.forEach((lab: ILaboratoryV3) => {
          options.push({
            key: lab.id,
            value: mixWB(lab.translation),
          })
        })

        // Add milva EuroFins Option
        if (data?.value?.allowMilvaEuroFins) {
          options.unshift({
            key: 'milvaEuroFins',
            value: 'Milva (EuroFins)',
          })
        }

        return options
      }),
      analysisDeliveryTimeOptions: store.getters.analysisDeliveryTimeOptionsAsArrayV2.map(
        (x: IAnalysisDeliveryTimeOptionV3) => ({
          key: x.id,
          value: x.days === 1
            ? mixWB('1_DAY_X_O_CLOCK', [x.hours.toString()])
            : mixWB('X_DAYS_X_O_CLOCK', [x.days.toString(), x.hours.toString()]),
        }),
      ),
      projectTypes: computed(() => store.getters.projectTypesAsArrayV3.map(
        (projectType: IProjectTypeV3) => ({
          id: projectType.id,
          title: mixWB(projectType.translation),
          isSelected: !!data?.value?.allowedProjectTypeIDs.includes(projectType.id),
          isActive: projectType.options.isActive,
        }),
      )),
      projectOptions: computed(() => store.getters.projectOptionsAsArrayV3.map(
        (projectOption: IProjectOptionV3) => ({
          id: projectOption.id,
          title: mixWB(projectOption.translation),
          isSelected: !!data?.value?.autoSelectedProjectOptionIDs.includes(projectOption.id),
          isActive: projectOption.options.isActive,
        }),
      )),
      onAllowMilvaEuroFinsUpdate,
      onProjectTypeUpdate,
      onProjectOptionUpdate,
      onSubmit,
      data,
      isLoading,
      isNew,
      successMessage,
    }
  },
})

